import React, { useState } from "react";
import Rating from '@mui/material/Rating';
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import IconButton from '@mui/material/IconButton'

import './reviewsData.css'

const Review = ({ review }) => {
    const [ textExpanded, setExpandedText ] = useState(false);

    function expandText() {
        setExpandedText(!textExpanded)
    }

    return (
        <Card className="reviewCard">
            <div className="reviewAuthor">
                <Avatar src={review.profile_photo_url} />
                <div className="reviewerName">{review.author_name}</div>
            </div>
            <Rating value={parseInt(review.rating)} readOnly />
            <div>{review.relative_time_description}</div>
            {
                review.text.length > 250 ? 
                    textExpanded ? <div style={{ marginTop: "10px" }}>{review.text}<IconButton style={{ float: "right", backgroundColor: "var(--color-primary)" }} onClick={expandText}><KeyboardArrowUpIcon /></IconButton></div> :
                        <div style={{ marginTop: "10px" }}>{review.text.substring(0, 250)}...<IconButton style={{ float: "right", backgroundColor: "var(--color-primary)" }} onClick={expandText} color="inherit"><KeyboardArrowDownIcon /></IconButton></div> : 
                        <div style={{ marginTop: "10px" }}>{review.text}</div>
            }
        </Card>
    )
}

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ReviewsData = (props) => {
    console.log(props.graphData);

    const reviewList = []
    for (const [key, review] of props.graphData.entries()) {
        reviewList.push(<Review key={key} review={review} />)
    }

    return (
        <ul>
            {reviewList}
        </ul>
    );
};
