import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ReviewsData } from "../components/reviewsData";
import { getReviews } from "../utils/customApiCall";

const ReviewsContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
      if (account && !graphData && inProgress === InteractionStatus.None) {
          const request = {
              ...apiRequest,
              account: account
          };
          instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
              getReviews(response.accessToken).then(response => setGraphData(response));
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request);
              }
          });
      }
  }, [account, inProgress, instance, graphData]);

  return (
      <div>
          { graphData ? <ReviewsData graphData={graphData} /> : null }
      </div>
  );
};

const ReviewsPage = () => (
  <Layout>
    <Seo title="Reviews" />
    <h1>Reviews</h1>
    <p>Google Reviews List</p>
    <ReviewsContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ReviewsPage
